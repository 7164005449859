var render = function () {
  var _vm$translation, _vm$translation2, _vm$translation3, _vm$translation4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TranslationEditorMolecule', {
    staticClass: "translation-editor-molecule"
  }, [_c('header', [_c('LabelAtom', {
    attrs: {
      "label": _vm.labelWithTranslationStatus
    }
  }), _vm._l(_vm.languages, function (language) {
    var _language$translatedT, _language$translatedT2;
    return _c('IconButtonAtom', {
      key: language.id,
      attrs: {
        "disabled": language.isDisabled
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('set-active-language-id', language.id);
        }
      }
    }, [_c('span', [_vm._v(" " + _vm._s(language.label) + " ")]), (_language$translatedT = language.translatedText) !== null && _language$translatedT !== void 0 && _language$translatedT.isCompleted ? _c('CheckIcon', {
      attrs: {
        "size": "16"
      }
    }) : _c('AlertTriangleIcon', {
      attrs: {
        "size": "16"
      }
    }), (_language$translatedT2 = language.translatedText) !== null && _language$translatedT2 !== void 0 && _language$translatedT2.isDirty && _vm.hasSave ? _c('SaveIcon', {
      attrs: {
        "size": "16"
      }
    }) : _vm._e()], 1);
  })], 2), _c('main', [_c('textarea', {
    domProps: {
      "value": (_vm$translation = _vm.translation) === null || _vm$translation === void 0 ? void 0 : _vm$translation.text
    },
    on: {
      "input": _vm.handleInput
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "preview"
  }, [_vm.markdownEnabled ? _c('VueMarkdown', {
    staticClass: "markdown",
    attrs: {
      "source": (_vm$translation2 = _vm.translation) === null || _vm$translation2 === void 0 ? void 0 : _vm$translation2.text
    }
  }) : _vm._e()], 1)]), _c('footer', [_vm.hasSaveAll ? _c('IconButtonAtom', {
    attrs: {
      "disabled": !((_vm$translation3 = _vm.translation) !== null && _vm$translation3 !== void 0 && _vm$translation3.canSave)
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('save-translated-text', _vm.translation.id);
      }
    }
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("save")])]) : _vm._e(), _vm.hasRestore ? _c('IconButtonAtom', {
    attrs: {
      "disabled": !((_vm$translation4 = _vm.translation) !== null && _vm$translation4 !== void 0 && _vm$translation4.canRestore)
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('restore-translated-text', _vm.translation.id);
      }
    }
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("restore")])]) : _vm._e(), _vm.hasSaveAll ? _c('IconButtonAtom', {
    staticClass: "save-all",
    attrs: {
      "disabled": !_vm.canSaveAll
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('save-all-translated-texts');
      }
    }
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("save all")])]) : _vm._e(), _vm.hasRestoreAll ? _c('IconButtonAtom', {
    attrs: {
      "disabled": !_vm.canRestoreAll
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('restore-all-translated-texts');
      }
    }
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("restore all")])]) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }