<template>
  <TranslationEditorMolecule class="translation-editor-molecule">
    <header>
      <LabelAtom :label="labelWithTranslationStatus" />
      <IconButtonAtom
        v-for="language in languages"
        :key="language.id"
        :disabled="language.isDisabled"
        @click="$emit('set-active-language-id', language.id)"
      >
        <span>
          {{ language.label }}
        </span>
        <CheckIcon size="16" v-if="language.translatedText?.isCompleted" />
        <AlertTriangleIcon size="16" v-else />
        <SaveIcon size="16" v-if="language.translatedText?.isDirty && hasSave" />
      </IconButtonAtom>
    </header>
    <main>
      <textarea :value="translation?.text" @input="handleInput" />
      <div class="preview">
        <VueMarkdown v-if="markdownEnabled" class="markdown" :source="translation?.text" />
      </div>
    </main>
    <footer>
      <IconButtonAtom v-if="hasSaveAll" :disabled="!translation?.canSave" @click="$emit('save-translated-text', translation.id)">
        <span class="label">save</span>
      </IconButtonAtom>
      <IconButtonAtom v-if="hasRestore" :disabled="!translation?.canRestore" @click="$emit('restore-translated-text', translation.id)">
        <span class="label">restore</span>
      </IconButtonAtom>
      <IconButtonAtom class="save-all" v-if="hasSaveAll" :disabled="!canSaveAll" @click="$emit('save-all-translated-texts')">
        <span class="label">save all</span>
      </IconButtonAtom>
      <IconButtonAtom v-if="hasRestoreAll" :disabled="!canRestoreAll" @click="$emit('restore-all-translated-texts')">
        <span class="label">restore all</span>
      </IconButtonAtom>
    </footer>
  </TranslationEditorMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { CheckIcon, AlertTriangleIcon, SaveIcon } from 'vue-feather-icons'
import VueMarkdown from 'vue-markdown'

import LabelAtom from '@/components/Atomic/Atoms/LabelAtom.vue'
import IconButtonAtom from '@/components/Atomic/Atoms/IconButtonAtom'

const TranslationEditorMolecule = styled('div')`
  display: grid;
  grid-gap: 1rem;
  background: ${({ theme }) => theme.colors.archonBlackTransparent};
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;

  > header,
  > footer {
    display: grid;
    grid-auto-flow: column dense;
    grid-auto-columns: min-content;
    grid-gap: 0.5rem;
  }

  > main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    > textarea {
      width: 100%;
      min-height: 300px;
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.archonBlack};
    }
    .preview {
      position: relative;
      .markdown {
        position: absolute;
        width: calc(100% - 1rem);
        height: calc(100% - 1rem);
        overflow: auto;
        padding: 0.5rem;
        color: ${({ theme }) => theme.colors.normalFontColor};
        background: ${({ theme }) => theme.colors.solidBG};
      }
    }
  }
  > footer {
    .save-all {
      margin-left: 2rem;
    }
  }
`

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    availableLanguages: {
      type: Array,
      required: true,
    },
    activeLanguageId: {
      type: String,
      required: true,
    },
    hasSave: {
      type: Boolean,
      default: false,
    },
    hasSaveAll: {
      type: Boolean,
      default: false,
    },
    canSaveAll: {
      type: Boolean,
      default: false,
    },
    hasRestore: {
      type: Boolean,
      default: false,
    },
    hasRestoreAll: {
      type: Boolean,
      default: false,
    },
    canRestoreAll: {
      type: Boolean,
      default: false,
    },
    translatedTexts: {
      type: Array,
      required: true,
    },
    markdownEnabled: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    TranslationEditorMolecule,
    CheckIcon,
    AlertTriangleIcon,
    SaveIcon,
    VueMarkdown,
    IconButtonAtom,
    LabelAtom,
  },
  computed: {
    labelWithTranslationStatus() {
      return `${this.title} (${this.languages.filter(f => f.translatedText?.isCompleted)?.length} / ${this.languages.length})`
    },
    languages() {
      return this.availableLanguages.map(languageId => {
        const translatedText = this.translatedTexts.find(f => f.language === languageId)
        return {
          id: languageId,
          label: languageId,
          isDisabled: translatedText?.language === this.activeLanguageId,
          translatedText: translatedText,
        }
      })
    },
    translation() {
      return this.translatedTexts.find(f => f.language === this.activeLanguageId)
    },
  },
  methods: {
    handleInput($event) {
      const translatedText = {
        id: this.translation.id,
        text: $event.target.value,
      }
      this.$emit('set-translated-text', translatedText)
    },
  },
}
</script>
